const { Sentry } = window;

export const canRunSentry = () =>
  WP_ENV === 'production' && typeof Sentry !== 'undefined';

export const reportMessage = (message, extra = {}) => {
  if (canRunSentry()) {
    Sentry.captureMessage(message, { extra });
  }
};

export const reportAjaxError = (jqXHR, errorThrown) => {
  if (canRunSentry()) {
    Sentry.captureMessage(errorThrown || jqXHR.statusText, {
      extra: {
        status: jqXHR.status,
        error: errorThrown || jqXHR.statusText,
        response: jqXHR.responseText.substring(0, 100),
      },
    });
  }
};
