import config from '../environment/config';

export default {
  graphql: (id = '', isPublic = false) =>
    `${config('API_URL')}/graphql${(isPublic && `/public`) || ''}${
      (id && `?gql_id=${id}`) || ''
    }`,

  // WILL ALSO LOG USER INTO WP AND CP
  LOGIN: `${config('API_URL')}/api/login`,
  SUBMIT_OTP: `${config('API_URL')}/api/login/otp`,
  REGISTER: `${config('API_URL')}/api/profile/register`,
  VERIFY_CODE: `${config('API_URL')}/api/password/verification`,

  // GENERAL PURPOSE
  ADD_INTEREST: `${config('API_URL')}/api/add-to-interests`, // append post id
  CHECK_INTEREST: `${config('API_URL')}/api/check-interest`, // append post id

  // DIRECT API
  REQUEST_OTP: `${config('API_URL')}/api/password/forgot`,
  FORGOT_PASSWORD: `${config('API_URL')}/api/password/forgot`,
  SET_PASSWORD: `${config('API_URL')}/api/password`,
  COUNTRY_CODES: `${config('API_URL')}/api/country_codes`,
  SLACK_NOTIFY: `${config('API_URL')}/api/slack/notify`,
  PRODUCT_DETAILS: `${config('API_URL')}/api/product-details`,

  // CHECKOUT
  PROFILE: `${config('API_URL')}/api/profile`,
  CHECKOUT_BEGIN: `${config('API_URL')}/api/checkout/begin`,

  //BLOG FILTER:
  FILTER_BLOGS: `${WP_SITE_URL}/wp-json/blog_filtering/v1/get_blog_data_by_filter`,

  // ADDRESS
  ADDRESS: `${config('API_URL')}/api/addresses`,

  // ACCESSORIES CATEGORIES
  ACCESSORIES_CATEGORIES: `${config('API_URL')}/api/accessories/categories`,

  // LEAD UPDATE PURCHASE TYPE
  UPDATE_LEAD_PURCHASE_TYPE: `${config('API_URL')}/api/update-purchase-type`,

  // LEAD UPDATE DECLINE REASON
  UPDATE_LEAD_DECLINE_REASON: `${config('API_URL')}/api/update-decline-reason`,

  // GET CURRENCY
  GET_CURRENCY: `${WP_SITE_URL}/wp-json/v1/get_currency`,
  
  // SUBSCRIBE NEWSLETTER
  SUBSCRIBE_NEWSLETTER: `${config('API_URL')}/shop/newsletter-subscriber`,
};
