// @flow
const PROD = 'luxity.co.za';
const PROD_WWW = 'www.luxity.co.za';
const AWS = 'aws.luxity.bdev.co.za';
const DEMO = 'luxity.bdev.co.za';
const DEV = 'localhost:9125';

export default function getConfigForEnvironment(key) {
  if (!window) return getConfig(DEV)[key];

  switch (window.location.host) {
    case DEV:
      return getConfig(DEV)[key];
    case PROD:
    case PROD_WWW:
      return getConfig(PROD)[key];
    case AWS:
      return getConfig(AWS)[key];
    case DEMO:
      return getConfig(DEMO)[key];
    default:
      return getConfig(DEMO)[key];
  }
}

function getConfig(environment: string = DEV) {
  const config = {
    API_URL: {
      [PROD]: 'https://api.luxity.co.za',
      [AWS]: 'https://aws.api.luxity.bdev.co.za',
      [DEMO]: 'https://api-bdev.luxity.co.za',
      [DEV]: 'http://localhost:8008',
    },
    WP_API: {
      [PROD]: 'https://luxity.co.za',
      [AWS]: 'https://aws.luxity.bdev.co.za',
      [DEMO]: 'https://luxity.bdev.co.za',
      [DEV]: 'http://localhost:9125',
    },
    DOMAIN: {
      [PROD]: '.luxity.co.za',
      [AWS]: '.aws.luxity.bdev.co.za',
      [DEMO]: '.bdev.co.za',
      [DEV]: 'localhost',
    },
    CLIENT_PLATFORM: {
      [PROD]: 'https://app.luxity.co.za',
      [AWS]: 'https://aws.app.luxity.bdev.co.za',
      [DEMO]: 'https://app-luxity.bdev.co.za',
      [DEV]: 'http://localhost:3000',
    },
    CHECKOUT: {
      [PROD]: 'https://luxity.co.za/checkout/',
      [AWS]: 'https://aws.luxity.bdev.co.za/checkout/',
      [DEMO]: 'https://luxity.bdev.co.za/checkout/',
      [DEV]: 'http://localhost:9125/checkout/',
    },
    CART: {
      [PROD]: 'https://luxity.co.za/cart/',
      [AWS]: 'https://aws.luxity.bdev.co.za/cart/',
      [DEMO]: 'https://luxity.bdev.co.za/cart/',
      [DEV]: 'http://localhost:9125/cart/',
    },
  };

  return Object.keys(config).reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: config[curr][environment],
    }),
    {},
  );
}
