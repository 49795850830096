import initAccordion from './accordion';
import scrollToElement from './scroll-to-element';
import initSentry from './initSentry.js';

export default function entry() {
  window.jQuery(document).ready(() => {
    initSentry();
    initAccordion();
    scrollToElement();
  });

  return true;
}

entry();
