import { canRunSentry } from './helpers/Sentry';

export default () => {
  const { Sentry } = window;
  if (canRunSentry()) {
    Sentry.init({
      dsn: SENTRY_DSN,
    });
  }
};
