export const NavbarAssets = {
  accordionIcon: '/blog/wp-content/themes/bonline/images/arrow-right.svg',
  backIcon: '/blog/wp-content/themes/bonline/images/back-arrow.svg',
  logoSrc: '/blog/wp-content/themes/bonline/images/luxity-logo.svg',
  menuIcon: '/blog/wp-content/themes/bonline/images/menu.svg',
  inputSearchIcon: '/blog/wp-content/themes/bonline/images/search.svg',
  cartIcon: '/blog/wp-content/themes/bonline/images/navbar/cart.svg',
  closeIcon: '/blog/wp-content/themes/bonline/images/close.svg',
  accountIcon: '/blog/wp-content/themes/bonline/images/navbar/account.svg',
  interestIcon: '/blog/wp-content/themes/bonline/images/navbar/interest.svg',
  newBrandsImage: '/blog/wp-content/themes/bonline/images/navbar/new-arrivals.webp',
  bagsImage: '/blog/wp-content/themes/bonline/images/navbar/bags.webp',
  shoesImage: '/blog/wp-content/themes/bonline/images/navbar/shoes.webp',
  clothingImage: '/blog/wp-content/themes/bonline/images/navbar/apparel.webp',
  accessoriesImage: '/blog/wp-content/themes/bonline/images/navbar/accessories.webp',
  watchesImage: '/blog/wp-content/themes/bonline/images/navbar/watch.webp',
};
