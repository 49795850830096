export default () => {
  const { jQuery: $ } = window;
  $('.scroll_trigger_element').on('click', function (e) {
    e.preventDefault();
    const { targetElementSelector = '' } = $(this).data();
    const targetElement = $(`${targetElementSelector}`);

    if (!targetElement) {
      return;
    }

    $('html, body')
      .stop()
      .animate(
        {
          scrollTop: targetElement.offset().top,
        },
        400,
        'swing',
        function () {
          window.location.hash = targetElementSelector;
        },
      );
  });
};
