export default () => {
  const { jQuery: $ } = window;
  const accordionButton = $('.custom-accordion__button');
  accordionButton.on('click', function () {
    const contentElement = $(this).next();
    const parentElement = $(this).parents('.custom-accordion');
    const activeParentElement = $(this).parents('.custom-accordion--active');
    if (activeParentElement.prop('class')) {
      activeParentElement
        .removeClass('custom-accordion--active')
        .addClass('custom-accordion');
      contentElement.css({
        'max-height': 0,
      });
    } else {
      parentElement
        .removeClass('custom-accordion')
        .addClass('custom-accordion--active');
      const scrollHeight = contentElement.prop('scrollHeight');
      contentElement.css({
        'max-height': `${scrollHeight}px`,
      });
    }
  });
};
