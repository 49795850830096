export const stores = [
  {
    title: 'Nelson Mandela Square',
    location: 'Sandton',
    regularOpenDays: 'Mondays to Saturdays:',
    regularOpenHours: '09h00-19h00',
    holidayOpenDays: 'Sundays & Public Holidays:',
    holidayOpenHours: '09h00-18h00',
    image: '/blog/wp-content/themes/bonline/images/store/nav_nelson-mandela-square-store.webp',
    slug: 'nelson-mandela-square',
  },
  {
    title: 'Menlyn Maine',
    location: 'Pretoria',
    regularOpenDays: 'Mondays to Saturdays:',
    regularOpenHours: '09h00-18h00',
    holidayOpenDays: 'Sundays & Public Holidays:',
    holidayOpenHours: '09h00-17h00',
    image: '/blog/wp-content/themes/bonline/images/store/nav_menlyn-maine-store.webp',
    slug: 'menlyn-maine',
  },
  {
    title: 'Gateway',
    location: 'Durban',
    regularOpenDays: 'Mondays to Saturdays:',
    regularOpenHours: '09h00-19h00',
    holidayOpenDays: 'Fridays & Saturdays:',
    holidayOpenHours: '09h00-21h00',
    weekendOpenDays: 'Sundays',
    weekendOpenHours: '09h00-18h00',
    image: '/blog/wp-content/themes/bonline/images/store/nav_gateway.webp',
    slug: 'gateway-durban',
  },
  {
    title: 'V&A Waterfront',
    location: 'Cape Town',
    regularOpenDays: 'Monday to Sundays',
    regularOpenHours: '09h00-21h00',
    holidayOpenDays: 'Public Holidays:',
    holidayOpenHours: '09h00-21h00',
    image: '/blog/wp-content/themes/bonline/images/store/nav_v&a-waterfront.webp',
    slug: 'v-and-a-store',
  },
];
