import { h, render } from 'preact';
import React, { useState } from 'react';
import { LuxityFooter } from '@bonlineza/luxity-lib';
import { NotifyError, NotifySuccess } from '@bonlineza/luxity-lib';
import {
  buyLinks,
  sellLinks,
  helpLinks,
  contactLinks,
  quickiesLinks,
  socialLinks,
  companyLinks,
} from './links';
import api from '../../constants/api';
import '../../../scss/tailwind.css';

const Footer = () => {
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      fetch(
        api.SUBSCRIBE_NEWSLETTER,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        },
      )
        .then((response) => {
          if (response.ok) {
            NotifySuccess(
              'You have successfully subscribed to our newsletter.',
            );
            resolve({ success: true, data: response });
          } else {
            return response.json().then((errorData) => {
              const errorMessage = errorData.email;
              NotifyError(`Failed to subscribe to newsletter: ${errorMessage}`);
              resolve({ success: false, data: response, errorMessage });
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          NotifyError('Failed to subscribe to newsletter.');
          reject(error);
        });
    });
  };

  return (
    <div className="w-full">
      <LuxityFooter
        socialLinks={socialLinks}
        quickiesLinks={quickiesLinks}
        contactLinks={contactLinks}
        companyLinks={companyLinks}
        helpLinks={helpLinks}
        buyLinks={buyLinks}
        sellLinks={sellLinks}
        visaImgSrc="/blog/wp-content/themes/bonline/images/footer/visa.png"
        masterCardSrc="/blog/wp-content/themes/bonline/images/footer/mastercard.png"
        logoSrc="/blog/wp-content/themes/bonline/images/luxity-logo.svg"
        formIconSrc="/blog/wp-content/themes/bonline/images/white_arrow_right.svg"
        onSubmit={onSubmit}
      />
    </div>
  );
};

const footerContainer = document.querySelector(
  'div[data-id="footer-app"]',
);

if (footerContainer) {
  render(<Footer />, footerContainer);
}
