export const links = {
  contactUsLink: '/contact-us',
  ourStoryLink: '/about',
  sellToUsLink: '/sell-to-us',
  blogLink: '/blog',
  careersLink: '/blog/careers',
  productLink: '/products',
  faqLink: '/faq',
  privacyLink: '/privacy',
  shippingLink: '/shipping',
  termsConditionsLink: '/terms-conditions',
  cart: '/cart',
};
