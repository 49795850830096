/**
 * GA Handler is initialised in the 'header.php'
 */
export default (category, label, event = 'event', value = 0) => {
  if (window.gaHandler) {
    window.gaHandler.event(category, label, event, value);
  }
};

export const sendGAEvent = ({
  category,
  label,
  action = 'event',
  value = 0,
}) => {
  window.gaHandler.event(
    category,
    action,
    label,
    value,
  );
};