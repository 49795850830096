
import { sendGAEvent } from '../../helpers/gaTracking';
import { links } from '../Navbar/links';

export const buyLinks = [
  {
    label: 'New',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on /products/brands',
        label: 'New',
      });
    },
    href: '/products/brands',
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Bags',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on /products/bags',
        label: 'Bags',
      });
    },
    href: '/products/bags',
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Shoes',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on /products/shoes',
        label: 'Shoes',
      });
    },
    href: '/products/shoes',
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Clothing',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on /products/apparel',
        label: 'Clothing',
      });
    },
    href: '/products/apparel',
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Accessories',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on /products/accessories',
        label: 'Accessories',
      });
    },
    href: '/products/accessories',
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Watches',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on /products/watches',
        label: 'Watches',
      });
    },
    href: '/products/watches',
    trackEvent: true,
    target: '_self',
  },
];

export const helpLinks = [
  {
    label: 'FAQs',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.faqLink}`,
        label: 'FAQs',
      });
    },
    href: links.faqLink,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Shipping & Returns',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.shippingLink}`,
        label: 'Shipping & Returns',
      });
    },
    href: links.shippingLink,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Terms & Conditions',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.termsConditionsLink}`,
        label: 'Terms & Conditions',
      });
    },
    href: links.termsConditionsLink,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Privacy Policy',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.privacyLink}`,
        label: 'Privacy Policy',
      });
    },
    href: links.privacyLink,
    trackEvent: true,
    target: '_blank',
  },
];

export const sellLinks = [
  {
    label: 'Sell To Us',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.sellToUsLink}`,
        label: 'Sell To Us',
      });
    },
    href: links.sellToUsLink,
    trackEvent: true,
    target: '_blank',
  },
];

export const contactLinks = [
  {
    label: 'Contact Us',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.contactUsLink}`,
        label: 'Contact Us',
      });
    },
    href: links.contactUsLink,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'myorder@luxity.co.za',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on myorder@luxity.co.za',
        label: 'myorder@luxity.co.za',
      });
    },
    href: 'mailto:myorder@luxity.co.za',
    trackEvent: true,
    target: '_blank',
  },
  {
    label: '010 020 6171',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on 010 020 6171',
        label: '010 020 6171',
      });
    },
    href: 'tel:0100206171',
    trackEvent: true,
    target: '_blank',
    isContact: true,
  },
];

export const quickiesLinks = [
  {
    label: 'Shop',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.productLink}`,
        label: 'Shop',
      });
    },
    href: links.productLink,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Blog',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.blogLink}`,
        label: 'Blog',
      });
    },
    href: links.blogLink,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Contact',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.contactUsLink}`,
        label: 'Contact',
      });
    },
    href: links.contactUsLink,
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Careers',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.careersLink}`,
        label: 'Careers',
      });
    },
    href: links.careersLink,
    trackEvent: true,
    target: '_blank',
  },
];

export const socialLinks = [
  {
    label: 'Facebook',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on https://www.facebook.com/luxity.za',
        label: 'Facebook',
      });
    },
    href: 'https://www.facebook.com/luxity.za',
    icon: '/blog/wp-content/themes/bonline/images/footer/facebook-f.svg',
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Instagram',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on https://www.instagram.com/luxity.shop/',
        label: 'Instagram',
      });
    },
    href: 'https://www.instagram.com/luxity.shop/',
    icon: '/blog/wp-content/themes/bonline/images/footer/instagram.svg',
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Twitter',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on https://twitter.com/luxityZA',
        label: 'Twitter',
      });
    },
    href: 'https://twitter.com/luxityZA',
    icon: '/blog/wp-content/themes/bonline/images/footer/twitter.svg',
    trackEvent: true,
    target: '_blank',
  },
  {
    label: 'Pinterest',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: 'Click on https://za.pinterest.com/luxityZA/',
        label: 'Pinterest',
      });
    },
    href: 'https://za.pinterest.com/luxityZA/',
    icon: '/blog/wp-content/themes/bonline/images/footer/pinterest-p.svg',
    trackEvent: true,
    target: '_blank',
  },
];

export const companyLinks = [
  {
    label: 'Our Story',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.ourStoryLink}`,
        label: 'Our Story',
      });
    },
    href: links.ourStoryLink,
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Blog',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.blogLink}`,
        label: 'Blog',
      });
    },
    href: links.blogLink,
    trackEvent: true,
    target: '_self',
  },
  {
    label: 'Careers',
    gaInstance: () => {
      sendGAEvent({
        category: 'Link',
        action: `Click on ${links.careersLink}`,
        label: 'Careers',
      });
    },
    href: links.careersLink,
    trackEvent: true,
    target: '_self',
  },
];
