import { h, render } from 'preact';
import React, { useEffect, useState } from 'react';
import { LuxityDesktopHeader } from '@bonlineza/luxity-lib';
import { LuxityMobileHeader } from '@bonlineza/luxity-lib';
import { stores } from './stores';
import { NavbarAssets } from './icons';
import { links } from './links';
import { Notify } from '@bonlineza/luxity-lib';
import { sendGAEvent } from '../../helpers/gaTracking';
import api from '../../constants/api';
import '../../../scss/tailwind.css';
import config from '../../environment/config';

const Navbar = () => {

  const onInterestClick = () => {
    sendGAEvent({
      category: 'Add Interest',
      action: 'Banner',
      label: document.title,
    });
    window.location.assign(`${config('CLIENT_PLATFORM')}/interests`);
  };

  const onAccountClick = () => {
    window.location.assign(`${config('CLIENT_PLATFORM')}`);
  };

  const onCartClick = () => {
    window.location.assign(`${links.cart}`);
  };

  const onSellToUsClick = () => {
    window.location.assign(`${links.sellToUsLink}`);
  };

  const onSearch = () => {
    window.location.replace(links.productLink);
  };

  const categoryElements = [
    {
      slug: 'womens',
      name: "Women's",
      category: '',
    },
    {
      slug: 'mens',
      name: "Men's",
      category: '',
    },
  ];

  const onLogoClick = () => {
    window.location.assign(`/`);
  };

  return (
    <div className="w-full">
      <Notify />
      <div className="xlg:hidden">
        <LuxityMobileHeader
          accordionIcon={NavbarAssets.accordionIcon}
          backIcon={NavbarAssets.backIcon}
          logoSrc={NavbarAssets.logoSrc}
          menuIcon={NavbarAssets.menuIcon}
          inputSearchIcon={NavbarAssets.inputSearchIcon}
          onSearch={onSearch}
          onCartClick={onCartClick}
          cartIcon={NavbarAssets.cartIcon}
          cartCount={0}
          closeIcon={NavbarAssets.closeIcon}
          onAccountClick={onAccountClick}
          contactUsLink={links.contactUsLink}
          ourStoryLink={links.ourStoryLink}
          blogLink={links.blogLink}
          onSellToUsClick={onSellToUsClick}
          categoryElements={categoryElements}
          productsUrl={links.productLink}
          stores={stores}
          onLogoClick={onLogoClick}
          endpoint={api.graphql('', true)}
          shop
        />
      </div>
      <div className="hidden xlg:block">
        <LuxityDesktopHeader
          accountIcon={NavbarAssets.accountIcon}
          cartIcon={NavbarAssets.cartIcon}
          interestIcon={NavbarAssets.interestIcon}
          cartCount={0}
          logoSrc={NavbarAssets.logoSrc}
          categoryElements={categoryElements}
          newBrandsImage={NavbarAssets.newBrandsImage}
          bagsImage={NavbarAssets.bagsImage}
          shoesImage={NavbarAssets.shoesImage}
          clothingImage={NavbarAssets.clothingImage}
          accessoriesImage={NavbarAssets.accessoriesImage}
          watchesImage={NavbarAssets.watchesImage}
          stores={stores}
          contactNumber="010 020 6171"
          productsUrl="/products"
          onInterestClick={onInterestClick}
          onCartClick={onCartClick}
          onAccountClick={onAccountClick}
          inputSearchIcon={NavbarAssets.inputSearchIcon}
          onSearch={onSearch}
          onSellToUsClick={onSellToUsClick}
          blogLink={links.blogLink}
          contactUsLink={links.contactUsLink}
          ourStoryLink={links.ourStoryLink}
          onLogoClick={onLogoClick}
          endpoint={api.graphql('', true)}
          shop
        />
      </div>
    </div>
  );
};

const navbarContainer = document.querySelector(
  'div[data-id="navbar-app"]',
);

if (navbarContainer) {
  render(<Navbar />, navbarContainer);
}